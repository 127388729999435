<template>


  <div class="home">

    <!-- Emblem Image 엠블럼 삭제-->
    <!-- <div class="emblem">
      <img src="@/assets/images/knba-emblem.png" alt="Emblem" class="emblem-img">
    </div> -->

    <div id="myCarousel" class="carousel slide mb-6" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true"
          aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
        <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="3" aria-label="Slide 4"></button>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active"
          :style="{ backgroundImage: 'url(' + require('@/assets/images/main0.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'  }" style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="rgb(254, 254, 243, 0.75)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption text-start">
              <!-- <h2 style="margin-bottom: 2rem; font-weight: 600;">Smart Thermometer</h2>
              <p>Through the home medical device DoctorInHome <br>Try systematic management after hospital treatment.</p> -->
              <!-- <p>
                <a class="btn btn-lg btn-primary" href="/qna" v-if="$store.state.language === 'ko'">더 자세히 알아보기</a>
                <a class="btn btn-lg btn-primary" href="/qna" v-if="$store.state.language === 'en'">Learn more</a>
              </p> -->
            </div>
          </div>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: 'url(' + require('@/assets/images/main2.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'   }"
          style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--bs-secondary-color)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption">
              <h2 style="margin-bottom: 2rem; font-weight: 600;">Tonsillitis camera</h2>
              <p>DoctorInHome's second medical device <br>You can take care of your neck with a tonsillitis camera.</p>
            </div>
          </div>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: 'url(' + require('@/assets/images/main3.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'   }"
          style="background-size: 1900px 800px; ">
          <!-- <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false">
            <rect width="100%" height="100%" fill="var(--bs-secondary-color)" />
          </svg> -->
          <div class="container">
            <div class="carousel-caption">
              <h2 style="margin-bottom: 2rem; font-weight: 600;">Tonsillitis camera</h2>
              <p>DoctorInHome's second medical device <br>You can take care of your neck with a tonsillitis camera.</p>
            </div>
          </div>
        </div>
        <div class="carousel-item" :style="{ backgroundImage: 'url(' + require('@/assets/images/main4.png') + ')', backgroundSize: 'cover', backgroundPosition: 'center'  }"
          style="background-size: 1900px 800px; ">
          <div class="container">
            <div class="carousel-caption">
              <h2 style="margin-bottom: 2rem; font-weight: 600;">DoctorInHome</h2>
              <p>Steadily recorded child records are organized in an easy way to see.<br>Check your child's
                temperature/prescription record at a glance.</p>
            </div>
          </div>
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    </div>


    <!-- Marketing messaging and featurettes
================================================== -->
    <!-- Wrap the rest of the page in another container to center all the content. -->

    <div class="container marketing" style="padding-top: 5rem;">




      <!-- START THE FEATURETTES -->



      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-6">
          <h2 class="featurette-heading fw-normal lh-1"
            style="font-weight: 600 !important; font-size: 40px; text-align: center; margin-top: 1rem;"> The travel time
            in suburban <br>areas is<span style="color: #FE6F0F;"> at least 30 minutes</span></h2>
          <!-- <p class="lead" v-if="$store.state.language === 'ko'">어렵게 방문한 병원, 진단 후 아이 케어 <br>집에서 내 손으로 5분만에 끝! 닥터인홈을 경험해보세요.</p>
          <p class="lead" v-if="$store.state.language === 'en'">Hospital visited with difficulty, child care after
            diagnosis <br>At home, with my hands, it's over in 5 minutes! Experience Doctor in Home.</p> -->
          <div style="text-align: center;">
            <img src="@/assets/images/item-31.png" alt="스마트체온계 제품사진" class="bd-placeholder-img img-fluid mx-auto"
              width="660" height="465" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          </div>
        </div>
        <div class="col-md-6">
          <!-- <svg class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-bg)"/><text x="50%" y="50%" fill="var(--bs-secondary-color)" dy=".3em">500x500</text></svg> -->
          <!-- <img src="@/assets/images/item-1.jpg" alt="스마트체온계 제품사진" class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" aria-label="Placeholder: 500x500" preserveAspectRatio="xMidYMid slice" focusable="false"> -->
          <h2 class="featurette-heading fw-normal lh-1"
            style="font-weight: 600 !important; font-size: 40px;text-align: center; margin-top: 1rem;"><span
              style="color: #FE6F0F;">Diagnosis within 5 minutes<br></span>with DoctorInHome! </h2>
          <div style="text-align: center;">
            <img src="@/assets/images/item-32.png" alt="스마트체온계 제품사진" class="bd-placeholder-img img-fluid mx-auto"
              width="660" height="465" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          </div>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-12 what-is">
          <h2 class="featurette-heading fw-normal lh-1"
            style="margin-top: 10rem !important;  font-weight: 600 !important; font-size: 30px;">You have to take care
            of your child's health every day.</h2>
        </div>
        <div class="row" style="justify-content: center;">
          <div class="col-lg-4">
            <img src="@/assets/images/intro-21.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="430"
              height="330" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
            <h4 class="fw-normal2">Otitis media</h4>
            <p>Hearing loss / Cognitive decline / Language development decline</p>
          </div>
          <div class="col-lg-4">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
            <img src="@/assets/images/intro-22.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="430"
              height="330" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
            <h4 class="fw-normal2">Tonsillitis</h4>
            <p>Shape deformation / Snoring / Declining growth / Increasing risk of otitis media</p>
          </div>
        </div>
        <div class="col-md-12 what-is">
          <p class="lead">Missing treatment timing for otitis media or tonsillitis may cause <span
              style="font-weight: 600;">side effects or complications</span>. <br>Continuous observation and quick
            treatment are the way to protect our child's health. </p>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-12 what-is">
          <h2 class="featurette-heading fw-normal lh-1" style=" font-weight: 600 !important; font-size: 50px;">Prevent
            with DoctorInHome</h2>
        </div>
        <div class="row" style="justify-content: center;">
          <div class="col-md-12 what-is">
            <img src="@/assets/images/item-41.png" alt="스마트체온계 제품사진" class="bd-placeholder-img img-fluid mx-auto"
              width="700" height="500" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
            <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
          </div>
        </div>
      </div>

      <hr class="featurette-divider">

      <div class="row featurette" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-md-12 what-is">
          <h2 class="featurette-heading fw-normal lh-1" style=" font-weight: 600 !important; "> Be smart,<span
              style="color: #FE6F0F;"> Doctor in Home.</span></h2>
        </div>
        <div class="col-md-12 what-is">
          <img src="@/assets/images/item-3.png" alt="스마트체온계 제품사진"
            class="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="700" height="500"
            aria-label="Placeholder: 700x500" preserveAspectRatio="xMidYMid slice" focusable="false">
        </div>
        <div class="col-md-12 what-is">
          <p class="lead">It is easy to move photos with endoscopic cameras and APP interworking. <br>Check out the
            progression stages of our child's <span style="font-weight: 600;">otitis media</span> or <span
              style="font-weight: 600;">tonsillitis</span> everywhere. </p>
        </div>
      </div>

      <hr class="featurette-divider">

      <!-- /END THE FEATURETTES -->

      <!-- Three columns of text below the carousel -->
      <div class="row" data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
        <div class="col-lg-4">
          <img src="@/assets/images/intro-1.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
          <h4 class="fw-normal2">With my family</h4>
          <p>Any family member can record it. <br>Record our child's symptoms and check them in real time.</p>
        </div><!-- /.col-lg-4 -->
        <div class="col-lg-4">
          <img src="@/assets/images/intro-4.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
          <h4 class="fw-normal2">Professional care</h4>
          <p>You can check whether the disease has improved with an endoscopic camera. <br>Don't miss the period of
            simple treatment with constant management.</p>
        </div>
        <div class="col-lg-4">
          <!-- <svg class="bd-placeholder-img rounded-circle" width="140" height="140" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="var(--bs-secondary-color)"/></svg> -->
          <img src="@/assets/images/intro-2.png" alt="스마트체온계 제품사진" class="bd-placeholder-img" width="360" height="260"
            aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false">
          <h4 class="fw-normal2">Health care records</h4>
          <p>Health checks are easy with date/hour records. <br>Please find the right medicine for my child with the
            medication record.</p>
        </div>

      </div><!-- /.row -->

      <hr class="featurette-divider">

      <!-- 팝업창 / 메인 화면에만 등장 -->
      <!-- <div v-if="modalCheck || backgroundModalCheck" class="modal-wrap">
        <div class="modal-overlay" id="modalOverlay"></div>

        <div v-if="backgroundModalCheck && !backgroundTodayPopupClosed" class="background-modal-container">
          <img src="@/assets/images/popup.png" alt="배경 팝업창" class="background-modal-image">
          <div class="popup-btn">
            <button @click="closeBackgroundModal">Close</button>
            <button @click="closeBackgroundTodayPopup">Close for today</button>
          </div>
        </div> -->

        <!-- 오버레이와 메인 팝업 -->
        <!-- <div v-if="modalCheck && !todayPopupClosed">
          
          <div class="modal-container">
            <img src="@/assets/images/popup.jpg" alt="팝업창" class="modal-image">
            <div class="modal-btn">
              <button @click="closeModal">Close</button>
              <button @click="closeTodayPopup">Close for today</button>
            </div>
          </div>
        </div>

      </div> -->

      <!-- 팝업창 / 메인 화면에만 등장 -->
      <div v-if="modalCheck" class="modal-wrap">
        <div class="modal-overlay" id="modalOverlay"></div>
        <div class="modal-container">
          <img :src="currentImage" alt="팝업창" class="modal-image">
          <div class="modal-btn">
            <button @click="closeModal">Close</button>
            <button @click="closeTodayPopup">Close for today</button>
          </div>
        </div>
      </div>

    </div><!-- /.container -->


  </div>
</template>
<script>
// import axios from 'axios';
// import {reactive} from 'vue';
// import * as echarts from 'echarts';
// import ApexCharts from 'apexcharts';
import { onMounted } from 'vue';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default {
  name: 'Home',
  setup() {
    onMounted(() => {
      AOS.init({
        // Optional settings
        duration: 450,
      });
    });
  },
  data() {
    return {
      modalCheck: false,
      todayPopupClose: false,
      images: [
        require('@/assets/images/popup.jpg'),
        // require('@/assets/images/popup-4.png'),
        // require('@/assets/images/popup-3.png'),
        // require('@/assets/images/popup-5.png'),
      ],
      currentImageIndex: 0
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    }
  },
  created() {
    const cookies = document.cookie.split(';').map(cookie => cookie.trim());
    for (const cookie of cookies) {
      const [name, value] = cookie.split('=');
      if (name === 'todayPopupClose' && value === 'true') {
        this.todayPopupClose = true;
      }
      if (name === 'backgroundTodayPopupClosed' && value === 'true') {
        this.backgroundTodayPopupClosed = true;
      }
    }
  },
  mounted() {
    this.startSlideshow();

    // 화면이 로드될 때 모달을 열기 위해 modalCheck 값을 true로 설정
    if (!this.backgroundTodayPopupClosed) {
      this.backgroundModalCheck = true;
    }
    if (!this.todayPopupClose) {
      this.modalCheck = true;
    }
  },
  methods: {
    closeModal() {
      this.modalCheck = false;
    },
    closeTodayPopup() {
      this.todayPopupClose = true;
      this.modalCheck = false;

      // 현재 시간에서 24시간을 더한 만료 시간을 설정합니다.
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 24 * 60 * 60 * 1000);
      const expires = "expires=" + expirationDate.toUTCString();

      // 쿠키를 설정합니다.
      document.cookie = "todayPopupClose=true;" + expires + ";path=/";
    },
    startSlideshow() {
      setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
      }, 1800); //1.8초마다 이미지 변경
    },
    changeLanguage(lang) {
      this.$store.dispatch('changeLanguage', lang);
    },
    
  },
  components: {
  }

}


</script>
<style scoped>
@import 'aos/dist/aos.css';

.emblem {
  position: absolute;
  top: 74px;
  left: 10px;
  z-index: 1000;
}

.emblem-img {
  width: 17rem; /* 기본 크기 설정 */
  height: auto;
}

/* 작은 화면에서 엠블럼 크기 조정 */
@media (max-width: 1634px) {
  .emblem-img {
    width: 13rem;
  }
}

@media (max-width: 1200px) {
  .emblem-img {
    width: 12rem;
  }
}

@media (max-width: 980px) {
  .emblem-img {
    width: 10rem;
  }
}

@media (max-width: 768px) {
  .emblem-img {
    width: 9rem;
  }
}

@media (max-width: 576px) {
  .emblem-img {
    width: 8rem;
  }
}


.what-is {
  text-align: center;
  padding-bottom: 4rem;
}

hr {
  border-top: none;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  background-color: #44423b;
  border-radius: 50%; /* 둥근 모양으로 변경 */
}

.carousel-indicators button {
  background-color: #44423b;
  border-radius: 50%; /* 둥근 모양으로 변경 */
  /* 기본 버튼 색상 */
}

.fw-normal2 {
  padding-top: 2rem;
  font-weight: 600;
}

.carousel-item {
  height: 50rem;
}

.carousel-caption {
  bottom: 28rem;
  color: #27272A;
  text-align: left;
}

/* 모달 스타일 */
.background-modal-container {
  position: absolute;
  top: 12%;
  left: 14%;
  width: 30%;
  max-width: 500px;
  max-height: 90%;
  background: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 999;
}

.modal-wrap {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 배경 불투명도 설정 */
  z-index: 998; /* 모달 위로 오게 설정 */
}

.modal-container {
  position: absolute;
  top: 12%;
  left: 37%;
  width: 30%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  background: inherit;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  z-index: 1000; /* 오버레이 위로 오게 설정 */
}

.modal-image,
.background-modal-image {
  width: 100%;
  height: auto;
}

.popup-btn {
  background-color: #9b9fb9;
  width: 100%;
}

.modal-btn {
  background-color: transparent;
  width: 100%;
}

.popup-btn button {
  border: none;
  border-radius: 8px;
  margin: 10px;
  background-color: #9b9fb9;
  color: #fff;
}

.modal-btn button {
  border: none;
  border-radius: 8px;
  margin: 10px;
  background-color: transparent;
  color: #fff;
}

@media (max-width: 768px) {
  .background-modal-container,
  .modal-container {
    width: 80%;
    left: 10%;
  }
}

@media (max-width: 480px) {
  .background-modal-container,
  .modal-container {
    width: 90%;
    left: 5%;
  }
}

/* Card */
.card-hos {
  margin-bottom: 30px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);
}

.card-header-hos,
.card-footer-hos {
  border-color: #ebeef4;
  background-color: #fff;
  color: #798eb3;
  padding: 15px;
}

.card-title-hos {
  padding: 20px 0 15px 0;
  font-size: 18px;
  font-weight: 500;
  color: #012970;
  font-family: 'Noto Sans KR', sans-serif;
}

.card-title-hos span {
  color: #899bbd;
  font-size: 14px;
  font-weight: 400;
}

.card-body-hos {
  padding: 0 20px 20px 20px;
}

.card-img-overlay {
  background-color: rgba(255, 255, 255, 0.6);
}
</style>